.media {
    &.--broken {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #131313;
        
        .label {
            margin: {
                top: 8px;
                bottom: 8px;
            }
        }
    }
}
