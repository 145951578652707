.landingPage {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
        margin-bottom: 1rem;
    }
}
