.mediaThumbnail {
    background-color: #131313;

    &.--video {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
        }

        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            background-color: #000000aa;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                font-size: 48px;
                color: #eee;
            }
        }
    }
}
