.mediaContent {
    overflow: hidden;

    &.--cover {
        display: flex;
        justify-content: center;
        align-items: center;

        .mediaThumbnail {
            min-height: 240px;
            max-height: 80vh;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &.--grid {
        .overCount {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #00000066;
            display: flex;
            justify-content: center;
            align-items: center;

            .overCountText {
                font-size: 32px;
                color: #fff;
            }
        }

        img,
        video,
        .mediaThumbnail {
            width: 100%;
            height: auto;
            object-fit: cover;
            aspect-ratio: 1/1;
        }
    }
}
