.placeTag {
    margin: 8px;
    padding: 6px;
    border-color: #d5e6fb66;
    border-radius: 4px;
    background: url('../../assets/images/empty-map.png') center center / cover;

    &.--small {
        .title {
            justify-content: flex-start;
        }
    }

    .title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .subtitle {
        display: block;
    }

    .tagIcon {
        height: 24px;
        margin-right: 8px;
    }
}
