.mediaGallery {
    background-color: #131313;

    &:hover {
        .nav,
        .action {
            opacity: 1;
        }
    }

    .nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: {
            left: 8px;
            right: 8px;
        }
        opacity: 0.2;
        transition: opacity 0.2s;

        &.--left {
            left: 0;
        }

        &.--right {
            right: 0;
        }
    }

    // overwrites
    .image-gallery-slide-wrapper {
        height: 80vh;

        .image-gallery-swipe {
            height: 100%;
        }

        .image-gallery-slides {
            height: 100%;

            .image-gallery-slide {
                height: 100%;
                display: flex !important;
                align-items: center;
                justify-content: center;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}
