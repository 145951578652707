.quoteView {
    position: relative;
    padding: {
        top: 12px;
        bottom: 12px;
        left: 16px;
        right: 16px;
    }
    border-radius: 8px;


    .quoteIcon {
        position: absolute;
        opacity: 0.2;

        &:first-child {
            top: 0;
            left: -8px;
            transform: rotateZ(180deg);
        }

        &:last-child {
            bottom: 0;
            right: -8px;
        }
    }
}
