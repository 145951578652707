.errorPage {
    align-self: stretch;

    .notFoundError {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
