.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: linear-gradient(135deg, #fb3c7f, #ffc05e);

    .appHeader {
        display: flex;
        justify-content: center;
        height: 72px;
        padding: {
            top: 8px;
            bottom: 8px;
        }

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .appContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: {
            left: 8px;
            right: 8px;
        }
    }

    .appFooter {
        display: flex;
        justify-content: center;
        padding: {
            top: 16px;
            bottom: 16px;
        }
    }
}
