.post {
    width: 100%;
    max-width: 480px;

    &.--moment {
        background-color: #131313;

        .MuiCardHeader-content,
        .MuiCardHeader-subheader {
            color: #ededed;
        }

        .mediaView {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 90vh;
            max-height: 960px;

            img {
                height: 100%;
                object-fit: contain;
            }

            .header {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 1;
            }

            .tags {
                position: absolute;
                bottom: 48px;
                left: 0;
                width: 100%;
                margin-left: 12px;
                margin-right: 12px;

                .MuiChip-root {
                    background-color: #ffffffaa;
                }
            }
        }

        .actions {
            .MuiSvgIcon-root {
                color: #ededed;
            }
        }
    }

    .MuiCardHeader-subheader {
        font-size: 12px;
    }

    .actions {
        .likeButton,
        .commentButton {
            flex: 1;
        }
    }
}
